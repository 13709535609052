<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Cristian Darío Valencia Agudelo                                 ###### -->
<!-- ###### @date: Febrero 2025                                                        ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->

<template>
  <article class="parametrizacion">
    <header>
      <h3>Bodegas</h3>
    </header>
    <section>
      <v-container>
        <v-data-table
          :headers="headers"
          :items="filteredBodegas"
          :items-per-page="20"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Buscar"
              class="mx-4"
              append-icon="mdi-magnify"
            ></v-text-field>
          </template>
          <template v-slot:item="{ item, index }">
            <tr :class="index % 2 === 0 ? 'row-even' : 'row-odd'">
              <td>{{ item.codigoBodega }}</td>
              <td>{{ item.nombreBodega }}</td>
              <td>{{ item.codigoCentroCostos }}</td>
              <td>{{ formatBoolean(item.esMunicipio) }}</td>
              <td>{{ item.tipoExpediente }}</td>
              <td>{{ formatBoolean(item.activarExpediente) }}</td>
              <td>
                <v-btn small @click="abrirDialogo(item)">Editar</v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-container>
    </section>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Editar Bodega - {{ bodegaSeleccionada?.nombreBodega }}
        </v-card-title>
        <v-card-text>
          <v-form ref="bodegaForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-switch
                    v-model="edicionBodega.esMunicipio"
                    label="¿Es Municipio?"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="edicionBodega.tipoExpediente"
                    :items="tipoExpedienteItems"
                    label="Tipo Expediente"
                    dense
                    :menu-props="{offsetY:true}"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-switch
                    v-model="edicionBodega.activarExpediente"
                    label="Activar Expediente"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="guardarEdicionBodega">
            Guardar
          </v-btn>
          <v-btn color="blue darken-1" text @click="cerrarDialogo">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </article>
</template>

<script>
import Swal from 'sweetalert2';
import { mapState } from 'vuex';

export default {
  name: 'Bodegas',
  data: () => ({
    search: '',
    dialog: false,
    bodegas: [],
    headers: [
      { text: 'Código Bodega', value: 'codigoBodega' },
      { text: 'Nombre Bodega', value: 'nombreBodega' },
      { text: 'Código Centro Costos', value: 'codigoCentroCostos' },
      { text: 'Es Municipio', value: 'esMunicipio' },
      { text: 'Tipo Expediente', value: 'tipoExpediente' },
      { text: 'Activar Expediente', value: 'activarExpediente' },
      { text: 'Acciones', value: 'actions', sortable: false }
    ],
    bodegaSeleccionada: null,
    edicionBodega: {
      esMunicipio: false,
      tipoExpediente: 'NINGUNO',
      activarExpediente: false
    },
    tipoExpedienteItems: ['NINGUNO', 'DOCUWARE', 'HERINCO']
  }),
  computed: {
    ...mapState(['enterprise']),
    filteredBodegas() {
      if (!this.search) return this.bodegas;
      const term = this.search.toLowerCase();
      return this.bodegas.filter(bodega => {
        return (
          bodega.nombreBodega.toLowerCase().includes(term) ||
          bodega.codigoBodega.toString().includes(term) ||
          (bodega.codigoCentroCostos && bodega.codigoCentroCostos.toString().includes(term))
        );
      });
    }
  },
  mounted() {
    this.getBodegas();
  },
  methods: {
    getBodegas() {
      this.$http
        .get('msa-process-file/api/bodega/list/'+this.enterprise.code)
        .then(({ data }) => {
          this.bodegas = data;
        })
        .catch(err => console.log(err));
    },
    abrirDialogo(bodega) {
      this.bodegaSeleccionada = bodega;
      this.edicionBodega = {
        esMunicipio: bodega.esMunicipio === null ? false : bodega.esMunicipio,
        tipoExpediente: bodega.tipoExpediente,
        activarExpediente: bodega.activarExpediente === null ? false : bodega.activarExpediente
      };
      this.dialog = true;
    },
    cerrarDialogo() {
      this.dialog = false;
    },
    guardarEdicionBodega() {
      const payload = {
        bodegasId: {
          codigoBodega: this.bodegaSeleccionada.codigoBodega,
          idEmpresa: this.enterprise.code
        },
        esMunicipio: this.edicionBodega.esMunicipio,
        tipoExpediente: this.edicionBodega.tipoExpediente,
        activarExpediente: this.edicionBodega.activarExpediente
      };
      this.$http
        .post('msa-process-file/api/bodega/editarbodega', payload)
        .then(() => {
          const index = this.bodegas.findIndex(
            b => b.codigoBodega === this.bodegaSeleccionada.codigoBodega
          );
          if (index !== -1) {
            this.$set(this.bodegas, index, {
              ...this.bodegaSeleccionada,
              ...this.edicionBodega
            });
          }
          Swal.fire(
            'Bodega',
            'La bodega ha sido actualizada.',
            'success'
          );
          this.dialog = false;
        })
        .catch(err => console.log(err));
    },
    formatBoolean(value) {
      return value === true ? 'Sí' : 'No';
    }
  }
};
</script>

<style scoped>
.v-data-table-header {
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
}

.row-even {
  background-color: #fafafa;
}

.row-odd {
  background-color: #f5f5f5;
}

article.parametrizacion {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

article.parametrizacion > header {
  margin-top: 1rem;
  width: 90%;
}

article.parametrizacion > header h3 {
  text-align: left;
  color: #0D47A1;
  width: 100%;
}

article.parametrizacion > section .container {
  width: 100%;
  max-width: 100% !important;
}

article.parametrizacion > section {
  width: 100%;
}
</style>
